import axios from "axios";
import { AttributeItem, CategoryItem, VoucherItem, VoucherListResponse } from "./constants";
import { CalculateResultParamItemProps, CalculatedResultItemProps } from "@/components/Cart/constants";
import { initResult } from "@/stores/initialState";


export const getCategories = async (): Promise<Array<CategoryItem>> => {
  try {  
    const response = await axios.get(
      process.env.NEXT_PUBLIC_API_URL! + process.env.NEXT_PUBLIC_API_CATEGORY!
    );

    return response.data;
  } catch (error) {
    return []
  }
}

export const getAttributes = async (): Promise<Array<AttributeItem>> => {
  try {
    const response = await axios.get(
      process.env.NEXT_PUBLIC_API_URL! + process.env.NEXT_PUBLIC_API_ATTRIBUTE!
    );

    return response.data;
  } catch (error) {
    return []
  }
}

export const getCalculatedResult = async (
  data: CalculateResultParamItemProps, token: string
): Promise<CalculatedResultItemProps> => {
  try {
    const response = await axios.post(
      process.env.NEXT_PUBLIC_API_URL! + process.env.NEXT_PUBLIC_API_ORDER! + "/calculate",
      data,
      { headers: { Authorization: token } }
    );

    return response.data;
  } catch (error) {
    return initResult;
  }
}

export const fetchVouchers = async (token: string, data: any): Promise<VoucherListResponse | Array<VoucherItem>> => {
  try {
    const response = await axios.post(
      "/api/vouchers",
      data,
      { headers: { Authorization: token } }
    )

    if (response.data.status === "ok" || response.status === 200) {
      return response.data;
    } else {
      try {
        const body = {data: {error: response.data, type: "fetchVouchers"}, timestamp: new Date().getTime()};
        await axios.post(
          "/api/report",
          {data: CryptoJS.AES.encrypt(JSON.stringify(body), process.env.NEXT_PUBLIC_KEY || '').toString()}
        )
      } catch (error) {
        console.log(error);
      }
      return {
        status: "error",
        voucher_list: [],
      };
    }
  } catch (error: any) {
    // if (error?.message !== "Duplicate request detected") {
    //   try {
    //     const body = {data: {error: error?.response?.data || error, type: "fetchVouchers"}, timestamp: new Date().getTime()};
    //     await axios.post(
    //       "/api/report",
    //       {data: CryptoJS.AES.encrypt(JSON.stringify(body), process.env.NEXT_PUBLIC_KEY || '').toString()}
    //     )
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
    return {
      status: "error",
      voucher_list: [],
    };
  }
}

export const bindVoucher = async (token: string, data: any): Promise<VoucherItem | string> => {
  try {
    const response = await axios.post(
      "/api/vouchers",
      data,
      { headers: { Authorization: token } }
    )

    if (response.data.status === "ok") {
      return response.data;
    } else {
      let desc = "";

      switch (response.data.error) {
        case "Invalid credentials":
          desc = "An error occurred, please log in again and try again";
          break;

        case "this voucher can only be used for new customer":
        case "this coupon is only available to customers who have already purchased":
        case "already used this voucher":
        case "personal usage has reached the upper limit":
        case "the number of coupons used is full":
        case "this coupon is bound to someone who can use it":
          desc = response.data.error;
          break;

        case "you already have this voucher":
          desc = "You have entered this voucher already.";
          break;

        case "cannot find voucher":
          desc = "Voucher not found.";
          break;

        default:
          try {
            const body = {data: {...data, error: response.data.error, type: "bindVoucher"}, timestamp: new Date().getTime()};
            await axios.post(
              "/api/report",
              {data: CryptoJS.AES.encrypt(JSON.stringify(body), process.env.NEXT_PUBLIC_KEY || '').toString()}
            )
          } catch (error) {
            console.log(error);
          }
          desc = "Voucher does not exist";
      }

      return desc;
    }
  } catch (error: any) {
    // if (error?.message !== "Duplicate request detected") {
    //   try {
    //     const body = {data: {...data, error: error?.response?.data || error, type: "bindVoucher"}, timestamp: new Date().getTime()};
    //     await axios.post(
    //       "/api/report",
    //       {data: CryptoJS.AES.encrypt(JSON.stringify(body), process.env.NEXT_PUBLIC_KEY || '').toString()}
    //     )
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
    return error.response.data.error;
  }
}

export const getNewToken = async (
  token: string, refresh_token: string, uid: string
): Promise<string> => {
  try {
    const response = await axios.post(
      '/api/refresh',
      { token: refresh_token, username: uid },
      { headers: { Authorization: token } }
    );

    return response.data;
  } catch (error) {
    return "";
  }
}
